// This file meant to be imported only once! Use fuse.scss to access
@use '@angular/material' as mat;
// to the core Fuse and Angular Material mixins

// ngx-datatable
//@import '~@swimlane/ngx-datatable/themes/material';
@import '@swimlane/ngx-datatable/themes/material';

// Perfect scrollbar
//@import '~perfect-scrollbar/css/perfect-scrollbar';
@import 'perfect-scrollbar/css/perfect-scrollbar';

// Fuse
@import "./fuse";

// Theme
@import "./theme";
 
// Include core Angular Material styles
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

// Partials
@import "./partials/normalize";
@import "./partials/scrollbars";
@import "./partials/helpers";
@import "./partials/general";
@import "./partials/icons";
@import "./partials/colors";
@import "./partials/material";
@import "./partials/angular-material-fix";
@import "./partials/typography";
@import "./partials/docs";
@import "./partials/page-layouts";
@import "./partials/cards";
@import "./partials/navigation";
@import "./partials/forms";
@import "./partials/print";

// Plugins
@import "./partials/plugins/plugins";
