@use '@angular/material' as mat;
@mixin politica-privacidad-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    app-politica-privacidad {

        #politica-privacidad {

            background-color: mat.get-color-from-palette($accent,700);

            #politica-privacidad-body-wrapper {

                #politica-privacidad-body {
                    @if ($is-dark) {
                        background: mat.get-color-from-palette($fuse-navy, 600);
                    } @else {
                        background: map-get($background, card);
                    }

                    .top {

                        .subtitle {
                            color: map-get($foreground, secondary-text);
                        }
                    }

                    form {
                        @if ($is-dark) {
                            background: rgba(0, 0, 0, 0.12);
                        } @else {
                            background: rgba(0, 0, 0, 0.06);
                        }
                    }
                }
            }
        }
    }
}