@use '@angular/material' as mat;
@mixin home-theme($theme) {

    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    home {

        .card-preview:hover{
            background-color: mat.get-color-from-palette($accent,700);
        }
        
        .content {

            &.source {

                .cards {

                    .card {
                        border-bottom-color: map-get($foreground, divider);
                        
                    }
                }
            }
        }
    }
}
