// Import Fuse core library
@import "./@fuse/scss/core"; 
// Import app.theme.scss
@import "./app/app.theme"; 

.margint-top-60{
    margin-top: 60px; 
}
.content-form{
    height: auto !important;
    box-sizing: border-box;  
   // overflow: auto !important;
}
.content-form.result{
    //height: 300px !important;
    //overflow: auto !important;
    box-sizing: border-box;  
}  
 
.content-form-1{
    width: 190px !important;
    margin-top: 0px !important;
}
.mat-horizontal-content-container{
    padding: 0px !important;
}
.panel-alerta .mat-dialog-container{
    background-image:url(./assets/images/cards/portada1.jpg) !important ;
    
}

.mat-step-icon-state-edit {
    background-color: #008f51 !important; 
}